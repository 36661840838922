/* eslint-disable max-lines */
import React from 'react'
import i18n from 'i18next'
import AdminIcon from '@material-ui/icons/VpnKey'
import DescriptionIcon from '@material-ui/icons/Description'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ContractIcon from '@material-ui/icons/Work'
import AssignmentIcon from '@material-ui/icons/Assignment'
import { ReactComponent as ClientProduct } from 'assets/icons/ic-clientandprocucts.svg'
import { ReactComponent as CycleFolder } from 'assets/icons/ic-cyclefolder.svg'
import { ReactComponent as PieChart } from 'assets/icons/ic-pie-chart.svg'
import { ReactComponent as Wallet } from 'assets/icons/ic-wallet.svg'
import { ReactComponent as Restore } from 'assets/icons/ic-restore.svg'
import { ReactComponent as Support } from 'assets/icons/ic-support.svg'
import { ReactComponent as Logout } from 'assets/icons/ic-logout.svg'
import agreementsApi from 'resources/agreements'
import { saveAs } from 'file-saver'
import { base64toBlob } from 'common/utils/download'
import { messageServiceError } from 'help/message'
import _orderBy from 'lodash/orderBy'

const oldSystemUrl = 'https://sistemagpc.softbox.com.br/eldoc_hpf_view/'

const primaryMenus = {
  DASHBOARDS: 'dashboards',
  ADMIN: 'admin',
  COSTUMER_PRODUCT: 'costumerProduct',
  CYCLE_FOLDER: 'cycleFolder',
  AGREEMENTS: 'agreements',
  CASHFLOW: 'cashflow',
  PERCENTAGE_VALUE: 'percentageValue',
  PAYMENTS: 'payments',
  SUPPORT: 'support',
  ASCERTAINMENT_ACTPM: 'ascertainmentACTPM'
}

const handleDownload = () => {
  agreementsApi.exportarRelatorioEventosCota({})
    .then((res) => {
      const { conteudo: file, nomeArquivo: name, tipoArquivo: extension, mime: type } = res.data
      const blob = base64toBlob(file, type)
      return saveAs(blob, `${name}.${extension}`)
    })
    .catch(messageServiceError)
}

const handleOpenOldSystem = () => {
  window.open(oldSystemUrl, '_blank')
}

const handleDiretoryDrive = () => {
  window.open('https://drive.google.com/drive/folders/1JTkp7tXZ8LKbDynJI1rdCa6phivekjLt', '_blank')
}

export const getRoutes = (closeMenu, logout, openChangePasswordModal) => [
  {
    icon: <DashboardIcon />,
    title: i18n.t('menu.dashboards.title'),
    closeMenu,
    menuItem: primaryMenus.DASHBOARDS,
    subItems: [
      {
        path: '/inconsistencies-dashboard',
        title: i18n.t('menu.dashboards.inconsistencies'),
        closeMenu,
        subItems: []
      },
      {
        path: '/imports-exports-dashboard',
        title: i18n.t('menu.dashboards.importsExports'),
        closeMenu,
        subItems: []
      }
    ]
  },
  {
    icon: <AdminIcon />,
    title: i18n.t('menu.administration.title'),
    menuItem: primaryMenus.ADMIN,
    subItems: [
      {
        path: '/user',
        title: i18n.t('menu.administration.user'),
        closeMenu,
        subItems: []
      },
      {
        path: '/profile',
        title: i18n.t('menu.administration.profile'),
        closeMenu,
        subItems: []
      },
      {
        path: '/approval-flow',
        title: i18n.t('menu.administration.approvalFlux'),
        closeMenu,
        subItems: []
      },
      {
        path: '/calendar',
        title: i18n.t('menu.administration.calendarOfEvents'),
        closeMenu,
        subItems: [],
        permitAll: true
      }
    ]
  },
  {
    icon: <ClientProduct fill='rgba(0, 0, 0, 0.54)' />,
    title: i18n.t('menu.clientProducts.title'),
    menuItem: primaryMenus.COSTUMER_PRODUCT,
    subItems: _orderBy([
      {
        path: '/cva',
        title: i18n.t('menu.clientProducts.cva'),
        closeMenu,
        subItems: []
      },
      {
        path: '/channel',
        title: i18n.t('menu.clientProducts.channel'),
        closeMenu,
        subItems: []
      },
      {
        path: '/category',
        title: i18n.t('menu.clientProducts.category'),
        closeMenu,
        subItems: []
      },
      {
        path: '/customer',
        title: i18n.t('menu.clientProducts.sapClient'),
        closeMenu,
        subItems: []
      },
      {
        path: '/product-integration',
        title: i18n.t('menu.clientProducts.productIntegration'),
        closeMenu,
        subItems: []
      },
      {
        path: '/client-integration',
        title: i18n.t('menu.clientProducts.clientIntegration'),
        closeMenu,
        subItems: []
      },
      {
        path: '/products-group',
        title: i18n.t('menu.clientProducts.productGroup'),
        closeMenu,
        subItems: []
      },
      {
        path: '/brands',
        title: i18n.t('menu.clientProducts.brands'),
        closeMenu,
        subItems: []
      },
      {
        path: '/microregion',
        title: i18n.t('menu.clientProducts.microregion'),
        closeMenu,
        subItems: []
      },
      {
        path: '/region',
        title: i18n.t('menu.clientProducts.region'),
        closeMenu,
        subItems: []
      },
      {
        path: '/sku',
        title: i18n.t('menu.clientProducts.sku'),
        closeMenu,
        subItems: []
      },
      {
        path: '/segment',
        title: i18n.t('menu.clientProducts.segment'),
        closeMenu,
        subItems: []
      },
      {
        path: '/budget-type',
        title: i18n.t('menu.clientProducts.typeOfFund'),
        closeMenu,
        subItems: []
      },
      {
        path: '/internal-order',
        title: i18n.t('menu.clientProducts.internalOrder'),
        closeMenu,
        subItems: []
      },
      {
        path: '/ppa-produto',
        title: i18n.t('menu.clientProducts.ppaOfProduct'),
        closeMenu,
        subItems: []
      },
      {
        path: '/report-products',
        title: i18n.t('menu.clientProducts.productPPA'),
        closeMenu,
        subItems: []
      },
      {
        path: '/commodity',
        title: i18n.t('menu.clientProducts.commodity'),
        closeMenu,
        subItems: []
      },
      {
        path: '/economic-group',
        title: i18n.t('menu.clientProducts.economicGroup'),
        closeMenu,
        subItems: []
      },
      {
        path: '/sell-in-integration',
        title: i18n.t('menu.clientProducts.sellin'),
        closeMenu,
        subItems: []
      }
    ], 'title', 'asc')
  },
  {
    icon: <CycleFolder fill='rgba(0, 0, 0, 0.54)' />,
    title: i18n.t('menu.cycleFolder.title'),
    menuItem: primaryMenus.CYCLE_FOLDER,
    subItems: [
      {
        path: '/appreciation',
        title: i18n.t('menu.appreciation.volume'),
        closeMenu,
        subItems: []
      },
      {
        path: '/migration',
        title: i18n.t('menu.cycleFolder.migration'),
        closeMenu,
        subItems: []
      },
      {
        path: '/appreciationCategoryPercentage',
        title: i18n.t('menu.appreciation.percentual'),
        closeMenu,
        subItems: []
      },
      {
        path: '/cycleFolder',
        title: i18n.t('menu.cycleFolder.planning'),
        closeMenu,
        subItems: []
      },
      {
        path: '/counting',
        title: i18n.t('menu.cycleFolder.ascertainment'),
        closeMenu,
        subItems: []
      },
      {
        title: i18n.t('menu.cycleFolder.appreciation.title'),
        subItems: [
          {
            path: '/appreciation/financeVolume',
            title: i18n.t('menu.cycleFolder.appreciation.volume'),
            closeMenu,
            subItems: []
          },
          {
            path: '/appreciation/categoryPercentage',
            title: i18n.t('menu.cycleFolder.appreciation.percentual'),
            closeMenu,
            subItems: []
          }
        ]
      },
      {
        title: i18n.t('menu.cycleFolder.reports.title'),
        subItems: [
          {
            path: '/report-finance',
            title: i18n.t('menu.cycleFolder.reports.finances'),
            closeMenu,
            subItems: []
          },
          {
            path: '/report-cycle-folder-counting-detail',
            title: i18n.t('menu.cycleFolder.cycleFolderAscertainmentDetails'),
            closeMenu,
            subItems: []
          },
          {
            path: '/cycle-folder/reports/general',
            title: i18n.t('menu.cycleFolder.reports.generalCycleFolder'),
            closeMenu,
            subItems: []
          },
          {
            path: '/cycle-folder/reports/regional-cycle-folder',
            title: i18n.t('menu.cycleFolder.reports.regionalCycleFolder'),
            closeMenu,
            subItems: []
          },
          {
            path: '/cycle-folder/reports/resume-funds-tactics-off',
            title: i18n.t('menu.cycleFolder.reports.fundsResumeOff'),
            closeMenu,
            subItems: []
          },
          {
            path: '/cycle-folder/reports/resume-funds-tactics-on',
            title: i18n.t('menu.cycleFolder.reports.fundsResumeOn'),
            closeMenu,
            subItems: []
          },
          {
            path: '/cycle-folder/reports/detailed-maintenance',
            title: i18n.t('menu.cycleFolder.reports.detailedCycleFolderMaintenence'),
            closeMenu,
            subItems: []
          },
          {
            path: '/cycle-folder/reports/payments',
            title: i18n.t('menu.cycleFolder.reports.cycleFolderPayments'),
            closeMenu,
            subItems: []
          }
        ]
      }
    ]
  },
  {
    icon: <ContractIcon />,
    title: i18n.t('menu.agreements.title'),
    menuItem: primaryMenus.AGREEMENTS,
    subItems: [
      {
        title: i18n.t('menu.agreements.registrationData.title'),
        subItems: [
          {
            path: '/target-execution-category',
            title: i18n.t('menu.agreements.registrationData.executionCategories'),
            closeMenu,
            subItems: []
          },
          {
            path: '/categoria-pack',
            title: i18n.t('menu.agreements.registrationData.categoryPack'),
            closeMenu,
            subItems: []
          },
          {
            title: i18n.t('menu.agreements.registrationData.clientPDV.title'),
            subItems: [
              {
                path: '/customer-pdv',
                title: i18n.t('menu.agreements.registrationData.clientPDV.register'),
                closeMenu,
                subItems: []
              }/* ,
              {
                path: '/pdv-store-integration',
                title: i18n.t('menu.agreements.registrationData.clientPDV.integration'),
                closeMenu,
                subItems: []
              } */
            ]
          },
          {
            path: '/impulse-item',
            title: i18n.t('menu.clientProducts.itemOfImpulse'),
            closeMenu,
            subItems: []
          },
          {
            path: '/questions-goal-execution',
            title: i18n.t('menu.agreements.registrationData.questionsGoalsExecution'),
            closeMenu,
            subItems: []
          },
          {
            path: '/target-execution-product',
            title: i18n.t('menu.agreements.registrationData.productsGoalsExecution'),
            closeMenu,
            subItems: []
          },
          {
            path: '/innovation-products',
            title: i18n.t('menu.agreements.registrationData.productsOfInnovation'),
            closeMenu,
            subItems: []
          },
          {
            path: '/subregion',
            title: i18n.t('menu.agreements.registrationData.subRegion'),
            closeMenu,
            subItems: []
          }
        ]
      },
      {
        path: '/lever',
        title: i18n.t('menu.leversConfiguration'),
        closeMenu,
        subItems: []
      },
      {
        path: '/agreements',
        title: i18n.t('menu.agreementsOfCostumers'),
        closeMenu,
        subItems: []
      },
      {
        path: '/calc',
        title: i18n.t('menu.calculations'),
        closeMenu,
        subItems: []
      },
      {
        path: '/trade-agreements-ascertainment',
        title: i18n.t('menu.agreements.ascertainment'),
        closeMenu,
        subItems: []
      },
      {
        title: i18n.t('menu.agreements.reports.title'),
        subItems: [
          {
            path: '/lever/details-report',
            title: i18n.t('menu.agreements.reports.leversDetails'),
            closeMenu,
            subItems: []
          },
          {
            path: '/ascertainment/details-report',
            title: i18n.t('menu.agreements.reports.ascertainmentDetails'),
            closeMenu,
            subItems: []
          },
          {
            path: '/lever/abstract-report',
            title: i18n.t('menu.agreements.reports.leversResume'),
            closeMenu,
            subItems: []
          },
          {
            title: i18n.t('general.actions.exportQuoteEvents'),
            closeMenu,
            onClick: () => handleDownload(),
            subItems: [],
            permissions: ['ROLE_VISUALIZAR_RELATORIO_EVENTOS_COTA']
          },
          {
            path: '/trade-agreements-ascertainment/one-page',
            title: i18n.t('routes.tradeAgreementsAscertainment.onePage'),
            closeMenu,
            subItems: []
          }
        ]
      }
    ]
  },
  {
    path: '/ascertainment-actpm',
    icon: <AssignmentIcon />,
    title: i18n.t('menu.ascertainmentACTPM.title'),
    closeMenu,
    menuItem: primaryMenus.ASCERTAINMENT_ACTPM,
    subItems: []
  },
  {
    path: '/cashflow',
    icon: <DescriptionIcon />,
    title: i18n.t('menu.detached'),
    closeMenu,
    menuItem: primaryMenus.CASHFLOW,
    subItems: []
  },
  {
    path: '/percentage-value',
    icon: <PieChart fill='rgba(0, 0, 0, 0.54)' />,
    title: i18n.t('menu.percentageValue'),
    closeMenu,
    menuItem: primaryMenus.PERCENTAGE_VALUE,
    subItems: []
  },
  {
    icon: <Wallet fill='rgba(0, 0, 0, 0.54)' />,
    title: i18n.t('menu.payments.title'),
    menuItem: primaryMenus.PAYMENTS,
    subItems: [
      {
        path: '/payments/map',
        title: i18n.t('menu.payments.map'),
        closeMenu,
        subItems: []
      },
      {
        path: '/payments',
        title: i18n.t('menu.payments.managementOfFundsAndPayments'),
        closeMenu,
        subItems: []
      },
      {
        title: i18n.t('menu.payments.reports.title'),
        subItems: [
          {
            path: '/payments/report',
            title: i18n.t('menu.payments.reports.consolidatedReport'),
            closeMenu,
            subItems: []
          }
        ]
      }
    ]
  },
  {
    icon: <Support fill='rgba(0, 0, 0, 0.54)' />,
    title: i18n.t('menu.support.title'),
    closeMenu,
    menuItem: primaryMenus.SUPPORT,
    subItems: [
      {
        path: '/chat',
        title: i18n.t('menu.support.chat'),
        closeMenu,
        subItems: []
      },
      {
        id: 'changeMyPassword',
        title: i18n.t('menu.support.changeMyPassword'),
        closeMenu,
        onClick: openChangePasswordModal,
        permitAll: true,
        subItems: []
      },
      {
        id: 'requestUserEdition',
        title: i18n.t('menu.support.requestUserEdition'),
        closeMenu,
        path: '/user/edition_request',
        permitAll: true,
        subItems: []
      },
      {
        id: 'supportFiles',
        title: i18n.t('menu.support.supportFiles'),
        onClick: handleDiretoryDrive,
        permitAll: true,
        closeMenu,
        subItems: []
      }
    ]
  },
  {
    icon: <Logout fill='rgba(0, 0, 0, 0.54)' />,
    title: i18n.t('menu.out'),
    closeMenu,
    permitAll: true,
    subItems: [],
    onClick: logout
  }
]
